<template>
  <div class="main">
    <div class="row mb-3">
      <div class="col-12">
        <a href="/admin/banner/create" class="btn btn-primary">Create</a>
      </div>
    </div>
    <div class="card">
      <div class="card-header">
        <h3 class="card-title">Banners</h3>
      </div>
      <div class="card-body">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>#</th>
              <th>Image</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in banners" :key="index">
              <td>{{ item.id }}</td>
              <td><img :src="'/'+item.path" class="img-fluid" /></td>
              <td>
                <a :href="'/admin/banner/'+item.id+'/edit'" class="btn btn-link">Update</a>
                &nbsp;
                <a class="btn btn-link" v-on:click="ask_delete_banner(item.id)">Delete</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="card-footer clearfix">
        <ul class="pagination pagination-sm m-0 float-right">
          <li class="page-item"><a class="page-link" href="" v-on:click="click_pagination_page(1)">First</a></li>
          <li class="page-item" v-if="parseInt(page) - 4 > 0"><a class="page-link" href="" v-on:click="click_pagination_page(parseInt(page) - 4)">{{ parseInt(page) - 4 }}</a></li>
          <li class="page-item" v-if="parseInt(page) - 3 > 0"><a class="page-link" href="" v-on:click="click_pagination_page(parseInt(page) - 3)">{{ parseInt(page) - 3 }}</a></li>
          <li class="page-item" v-if="parseInt(page) - 2 > 0"><a class="page-link" href="" v-on:click="click_pagination_page(parseInt(page) - 2)">{{ parseInt(page) - 2 }}</a></li>
          <li class="page-item" v-if="parseInt(page) - 1 > 0"><a class="page-link" href="" v-on:click="click_pagination_page(parseInt(page) - 1)">{{ parseInt(page) - 1 }}</a></li>
          <li class="page-item active"><a class="page-link" href="" v-on:click="click_pagination_page(page)">{{ page }}</a></li>
          <li class="page-item" v-if="parseInt(page) + 1 <= page_total"><a class="page-link" href="" v-on:click="click_pagination_page(parseInt(page) + 1)">{{ parseInt(page) + 1 }}</a></li>
          <li class="page-item" v-if="parseInt(page) + 2 <= page_total"><a class="page-link" href="" v-on:click="click_pagination_page(parseInt(page) + 2)">{{ parseInt(page) + 2}}</a></li>
          <li class="page-item" v-if="parseInt(page) + 3 <= page_total"><a class="page-link" href="" v-on:click="click_pagination_page(parseInt(page) + 3)">{{ parseInt(page) + 3}}</a></li>
          <li class="page-item" v-if="parseInt(page) + 4 <= page_total"><a class="page-link" href="" v-on:click="click_pagination_page(parseInt(page) + 4)">{{ parseInt(page) + 4}}</a></li>
          <li class="page-item"><a class="page-link" href="" v-on:click="click_pagination_page(page_total)">Last</a></li>
        </ul>
      </div>
    </div>
  </div>
</template>
  
<script>
import { useRoute } from 'vue-router'

const axios = require('axios');
const Swal = require('sweetalert2');

export default {
  name: 'AdminBannerComponent',
  data() {
    return {
      limit: 10,
      page: 1,
      banners: [],
      page_total: 1,
      item_total: 0
    }
  },
  async created () {
    const route = useRoute()
    if (typeof route.query.limit != 'undefined') {
      this.limit = route.query.limit
    }
    if (typeof route.query.page != 'undefined') {
      this.page = route.query.page
    }
    await this.get_banner_list();
  },
  methods: {
    click_pagination_page(page) {
      this.$router.push({name: 'AdminBannerComponent', query: { limit: this.limit, page: page }})
    },
    click_pagination_next(value) {
      this.$router.push({name: 'AdminBannerComponent', query: { limit: this.limit, page: this.page + value }})
    },
    click_pagination_prev(value) {
      this.$router.push({name: 'AdminBannerComponent', query: { limit: this.limit, page: this.page - value }})
    },
    async get_banner_list() {
      let api = axios.create({
        timeout: 60 * 1000, // 1 minute
        // headers: {
        //     'Content-Type': 'application/json',
        //     'Access-Control-Allow-Origin': '*',
        //     'Access-Control-Allow-Methods': 'GET, PUT, POST, PATCH, DELETE, OPTIONS',
        //     'Access-Control-Allow-Headers': '*'
        // },
      });

      await api.get('https://vmplastic.com/banner_list.php?limit='+this.limit+'&page='+this.page, {
          headers: {
            'Authorization': localStorage.getItem('token')
          }
        })
        .then(function (response) {
          if (response.data.data) {
            console.log(response.data.data);
            this.banners = response.data.data;
            this.page = response.data.page_index;
            this.page_total = response.data.page_total;
            this.item_total = response.data.item_total;
            this.limit = response.data.item_per_page;
          } else {
            console.log('error');
              Swal.fire(
              'Error',
              '',
              'error'
            );
          }  
        }.bind(this))
        .catch(function (error) {
          console.error(error);
          Swal.fire(
            'Error',
            '',
            'error'
          )
        })
        .finally(function () {
          // always executed
        });
    },
    async ask_delete_banner(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "Are you sure want to delete this item!",
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {
          this.delete_banner(id);
        }
      });
    },
    async delete_banner(id) {
      let api = axios.create({
        timeout: 60 * 1000, // 1 minute
        // headers: {
        //     'Content-Type': 'application/json',
        //     'Access-Control-Allow-Origin': '*',
        //     'Access-Control-Allow-Methods': 'GET, PUT, POST, PATCH, DELETE, OPTIONS',
        //     'Access-Control-Allow-Headers': '*'
        // },
      });

      let form = new FormData();
      form.append('action', 'delete');
      form.append('id', id);

      await api.post('https://vmplastic.com/banner.php', form, {
          headers: {
            'Authorization': localStorage.getItem('token')
          }
        })
        .then(function (response) {
          if (response.data.is_error == false) {
            console.log(response.data);
            if (response.data.is_error == false && response.data.message == null) {
              Swal.fire({
                title: 'Deleted!',
                icon: 'success'
              }).then((result) => {
                if (result.value) {
                  window.location.reload();
                }
              });
            }
          } else {
            console.log('error');
            Swal.fire(
              'Error',
              response.data.message,
              'error'
            );
          }  
        }.bind(this));
    }
  }
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
<template>
  <div class="promotion">
    <section>
      <div class="container">
        <nav class="mt-4" aria-label="breadcrumb">
          <ol class="breadcrumb bg-color-transparent">
            <li class="breadcrumb-item"><a href="/">HOME</a></li>
            <li class="breadcrumb-item active" aria-current="/promotion">PROMOTION</li>
          </ol>
        </nav>
      </div>
    </section>
    <section class="page-section">
      <div class="container">
        <h2 class="text-center mb-0"><strong>โปรโมชั่น</strong></h2>
        <div class="divider-custom">
          <div class="divider-custom-line"></div>
          <div class="divider-custom-icon">
            <i class="fa-solid fa-star"></i>
          </div>
          <div class="divider-custom-line"></div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-4 col-lg-4"
            v-for="(item, index) in promotions" :key="index">
            <div class="row card mb-3">
              <div class="col-12">
                <img :src="'/'+item.image" class="img-fluid">
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <p class="text-overflow-ellipsis mb-0"><strong>{{ item.title }}</strong></p>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <p class="text-overflow-ellipsis" v-html="item.description"></p>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <p class="title-ping"><a class="cursor-pointer" v-on:click="click_promotion(item.id)"><strong>รายละเอียด ></strong></a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
const axios = require('axios');
const Swal = require('sweetalert2');

export default {
  name: 'PromotionComponent',
  data() {
    return {
      limit: 999999,
      promotions: [
        // {
        //   id: 1,
        //   image: "assets/promotion1.png",
        //   title: "สั่งซื้อภายในเดือนกันยายน 2566",
        //   description: "พิเศษส่วนลด 10% ยอดตั้งแต่ 50,000.- ขึ้นไป"
        // },
        // {
        //   id: 2,
        //   image: "assets/promotion1.png",
        //   title: "สั่งซื้อภายในเดือนกันยายน 2566",
        //   description: "พิเศษส่วนลด 12% ยอดตั้งแต่ 100,000.- ขึ้นไป"
        // },
        // {
        //   id: 3,
        //   image: "assets/promotion2.png",
        //   title: "สั่งซื้อภายในเดือนกันยายน 2566",
        //   description: "พิเศษส่วนลด 15% ยอดตั้งแต่ 200,000.- ขึ้นไป"
        // }
      ]
    }
  },
  async created () {
    await this.get_promotion_list();
  },
  methods: {
    async get_promotion_list() {
      let api = axios.create({
        timeout: 60 * 1000, // 1 minute
        // headers: {
        //     'Content-Type': 'application/json',
        //     'Access-Control-Allow-Origin': '*',
        //     'Access-Control-Allow-Methods': 'GET, PUT, POST, PATCH, DELETE, OPTIONS',
        //     'Access-Control-Allow-Headers': '*'
        // },
      });

      await api.get('https://vmplastic.com/promotion_list.php?limit='+this.limit)
        .then(function (response) {
          if (response.data.data) {
            console.log(response.data.data);
            this.promotions = response.data.data;
          } else {
            console.log('error');
              Swal.fire(
              'Error',
              '',
              'error'
            );
          }  
        }.bind(this))
        .catch(function (error) {
          console.error(error);
          Swal.fire(
            'Error',
            '',
            'error'
          )
        })
        .finally(function () {
          // always executed
        });
    },
    click_promotion(promotion_id) {
      this.$router.push({name: 'PromotionDetailComponent', params: { id: promotion_id }})
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .page-section {
    padding: 2rem 0 4rem 0;
  }
  .card {
    background-color: #F2F2F2;
    border: none;
    /* margin-bottom: 15px;
    border-radius: 15px; */
    padding: auto;
    height: 200px;
    margin: auto;
    background-position: center;
  }
  .card img {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
</style>
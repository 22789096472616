<template>
  <div class="main">
    <div class="row mb-3">
      <div class="col-12">
        <h1>{{ id == null ? 'Create' : 'Edit' }} User</h1>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="form-group">
          <label for="exampleInputName">Email</label>
          <input type="text" class="form-control" id="exampleInputName" placeholder="Enter Username"
            v-model="data.username" maxlength="100">
        </div>
        <div class="form-group">
          <label for="exampleInputName">Name</label>
          <input type="text" class="form-control" id="exampleInputName" placeholder="Enter Name"
            v-model="data.name" maxlength="100">
        </div>
        <div class="form-group">
          <label for="exampleInputPassword">Password</label>
          <input type="password" class="form-control" id="exampleInputPassword" placeholder="Enter Password"
            v-model="data.password" maxlength="100">
        </div>
        <div class="form-group">
          <label for="exampleInputConfirmPassword">Confirm Password</label>
          <input type="password" class="form-control" id="exampleInputConfirmPassword" placeholder="Enter Confirm Password"
            v-model="data.confirm_password" maxlength="100">
        </div>
      </div>
      <div class="card-footer">
        <button type="button" class="btn btn-primary" v-on:click="update_data()">{{ id == null ? 'Create' : 'Edit' }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { useRoute } from 'vue-router'

const axios = require('axios')
const Swal = require('sweetalert2')

export default {
  name: 'AdminUserUpdateComponent',
  data() {
    return {
      id: null,
      data: {
        name: null,
        username: null,
        password: null,
        confirm_password: null
      }
    }
  },
  async created(){
    const route = useRoute()

    if (typeof route.params.id != 'undefined') {
      this.id = route.params.id
      await this.get_data()
    }
  },
  methods: {
    async get_data() {
      let api = axios.create({
        timeout: 60 * 1000, // 1 minute
        // headers: {
        //     'Content-Type': 'application/json',
        //     'Access-Control-Allow-Origin': '*',
        //     'Access-Control-Allow-Methods': 'GET, PUT, POST, PATCH, DELETE, OPTIONS',
        //     'Access-Control-Allow-Headers': '*'
        // },
      });

      await api.get('https://vmplastic.com/user_list.php?id=' + this.id, {
          headers: {
            'Authorization': localStorage.getItem('token')
          }
        })
        .then(function (response) {
          if (response.data.data) {
            console.log(response.data.data);
            this.data.name = response.data.data[0].name;
            this.data.username = response.data.data[0].username;
          } else {
            console.log('error');
              Swal.fire(
              'Error',
              '',
              'error'
            );
          }  
        }.bind(this))
        .catch(function (error) {
          console.error(error);
          Swal.fire(
            'Error',
            '',
            'error'
          )
        })
        .finally(function () {
          // always executed
        });
    },
    async update_data() {
      console.log('update_user', this.data);
      if (this.data.name == null) {
        Swal.fire(
          'Error',
          'Please input name',
          'error'
        );
      } else if (this.data.username == null) {
        Swal.fire(
          'Error',
          'Please input username',
          'error'
        );
      } else if (this.id == null && (this.data.password == "" || this.data.confirm_password == null)) {
        Swal.fire(
          'Error',
          'Please input password.',
          'error'
        );
      } else if (this.id == null && (this.data.password.length < 6)) {
        Swal.fire(
          'Error',
          'Password mismatch.',
          'error'
        );
      } else if (this.id == null && (this.data.confirm_password == "" || this.data.confirm_password == null)) {
        Swal.fire(
          'Error',
          'Please input confirm password.',
          'error'
        );
      } else if (this.id == null && (this.data.password.confirm_password < 6)) {
        Swal.fire(
          'Error',
          'Confirm Please mismatch.',
          'error'
        );
      } else if (this.id == null && (this.data.password != this.data.confirm_password)) {
        Swal.fire(
          'Error',
          'Password not match.',
          'error'
        );
      } else {
        let api = axios.create({
          timeout: 60 * 1000, // 1 minute
          headers: {
            'Content-Type': 'multipart/form-data'
          //     'Content-Type': 'application/json',
          //     'Access-Control-Allow-Origin': '*',
          //     'Access-Control-Allow-Methods': 'GET, PUT, POST, PATCH, DELETE, OPTIONS',
          //     'Access-Control-Allow-Headers': '*'
          },
        });

        let form = new FormData();
        form.append('action', this.id == null ? 'create' : 'update');
        form.append('id', this.id);
        form.append('name', this.data.name);
        form.append('username', this.data.username);
        form.append('password', this.data.password);
        form.append('confirm_password', this.data.confirm_password);

        await api.post('https://vmplastic.com/user.php', form, {
            headers: {
              'Authorization': localStorage.getItem('token')
            }
          })
          .then(function (response) {
            if (response.data.is_error == false) {
              console.log(response.data);
              if (response.data.is_error == false && response.data.message == null) {
                this.data.name = response.data.data.name;
                this.data.username = response.data.data.username;
                this.data.password = "";
                this.data.confirm_password = "";

                Swal.fire(
                  'Success',
                  '',
                  'success'
                ).then((result) => {
                  if (result.value) {
                    this.$router.push({name: 'AdminUserComponent'})
                  }
                });
              }
            } else {
              console.log('error');
              Swal.fire(
                'Error',
                response.data.message,
                'error'
              );
            }  
          }.bind(this))
          .catch(function (error) {
            console.error(error);
            Swal.fire(
              'Error',
              '',
              'error'
            )
          })
          .finally(function () {
            // always executed
          });
      }
    }
  }
}
</script>
  
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
<template>
  <div class="main">
    <div class="row mb-3">
      <div class="col-12">
        <h1>Change Password</h1>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-body">
      <div class="form-group">
        <label for="exampleInputPassword">Password</label>
        <input type="password" class="form-control" id="exampleInputPassword" placeholder="Enter Password"
          v-model="form.password" maxlength="100">
      </div>
      <div class="form-group">
        <label for="exampleInputConfirmPassword">Confirm Password</label>
        <input type="password" class="form-control" id="exampleInputConfirmPassword" placeholder="Enter Confirm Password"
          v-model="form.confirm_password" maxlength="100">
      </div>
    </div>
    <div class="card-footer">
      <button type="button" class="btn btn-primary" v-on:click="submit()">Submit</button>
    </div>
  </div>
</template>
  
<script>
const axios = require('axios')
const Swal = require('sweetalert2')

export default {
  name: 'AdminChangePasswordComponent',
  data() {
    return {
      form: {
        password: "",
        confirm_password: ""
      }
    }
  },
  methods: {
    async submit() {
      if (this.form.password == "" || this.form.confirm_password == null) {
        Swal.fire(
          'Error',
          'Please input password.',
          'error'
        );
      } else if (this.form.password.length < 6) {
        Swal.fire(
          'Error',
          'Password mismatch.',
          'error'
        );
      } else if (this.form.confirm_password == "" || this.form.confirm_password == null) {
        Swal.fire(
          'Error',
          'Please input confirm password.',
          'error'
        );
      } else if (this.form.password.confirm_password < 6) {
        Swal.fire(
          'Error',
          'Confirm Please mismatch.',
          'error'
        );
      } else if (this.form.password != this.form.confirm_password) {
        Swal.fire(
          'Error',
          'Password not match.',
          'error'
        );
      } else {
        let api = axios.create({
          timeout: 60 * 1000, // 1 minute
          // headers: {
          //     'Content-Type': 'application/json',
          //     'Access-Control-Allow-Origin': '*',
          //     'Access-Control-Allow-Methods': 'GET, PUT, POST, PATCH, DELETE, OPTIONS',
          //     'Access-Control-Allow-Headers': '*'
          // },
        });

      await api.post('https://vmplastic.com/change_password.php', this.form, {
          headers: {
            'Authorization': localStorage.getItem('token')
          }
        })
        .then(function (response) {
          if (response.data.is_error == false) {
            console.log(response.data.data);
            Swal.fire({
              title: 'Change Password Success!',
              icon: 'success'
            }).then((result) => {
              if (result.value) {
                this.form = {
                  password: "",
                  confirm_password: ""
                };
              }
            });
          } else {
            console.log('error');
            Swal.fire(
              'Error',
              response.data.message,
              'error'
            );
          }  
        }.bind(this))
        .catch(function (error) {
          console.error(error);
          Swal.fire(
            'Error',
            '',
            'error'
          )
        })
        .finally(function () {
          // always executed
        });
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
<template>
  <div class="products">
    <section>
      <div class="container">
        <nav class="mt-4" aria-label="breadcrumb">
          <ol class="breadcrumb bg-color-transparent">
            <li class="breadcrumb-item"><a href="/">HOME</a></li>
            <li class="breadcrumb-item active" aria-current="/products">PRODUCTS</li>
          </ol>
        </nav>
      </div>
    </section>
    <section class="page-section">
      <div class="container">
        <h2 class="text-center mb-0"><strong>หมวดหมู่สินค้า</strong></h2>
        <div class="divider-custom">
          <div class="divider-custom-line"></div>
          <div class="divider-custom-icon">
            <i class="fa-solid fa-star"></i>
          </div>
          <div class="divider-custom-line"></div>
        </div>
        <div class="row">
          <div class="col-sm-6 col-md-6 col-lg-4 text-center category-item"
            v-for="(item, index) in categories" :key="index" v-on:click="click_category(item.id)">
            <div class="card" :class="{'category-item-active':item.id == category_id}">
              <div class="row mb-3">
                <div class="col-12 justify-content-center category-image">
                  <img :src="'/'+item.image" class="img-fluid">
                </div>
              </div>
              <div class="row">
                <div class="col-12 text-center">
                  <strong>{{ item.name }}</strong>
                </div>
              </div>
              <div class="row">
                <div class="col-12 text-center">
                  <p>{{ item.description }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="page-section">
      <div class="container">
        <div class="row">
          <div class="col-12 text-center">
            <h1><strong>{{ category_name }}</strong></h1>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-center">
            {{ category_description }}
          </div>
        </div>
        <div class="row" v-if="products.length > 0">
          <div class="col-sm-6 col-md-6 col-lg-4 text-center mb-3 mt-3" v-for="(item, index) in products" :key="index">
            <div class="row mb-3 product-image">
              <div class="col-12 justify-content-center">
                <img :src="'/'+item.image" class="img-fluid">
              </div>
            </div>
            <div class="row">
              <div class="col-12 text-center">
                <strong>{{ item.name }}</strong>
              </div>
            </div>
            <div class="row">
              <div class="col-12 text-center">
                {{ item.description }}
              </div>
            </div>
            <div class="row">
              <div class="col-12 text-center">
                {{ item.price }} ฿
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 d-flex justify-content-center">
              <nav aria-label="Page navigation">
                <ul class="pagination">
                  <li class="page-item" v-if="product_page > 1"><a class="page-link" v-on:click="click_pagination_page(1)">{{ '<' }}</a></li>
                  <li class="page-item" v-if="parseInt(product_page) - 4 > 0"><a class="page-link" v-on:click="click_pagination_page(parseInt(product_page) - 4)">{{ parseInt(product_page) - 4 }}</a></li>
                  <li class="page-item" v-if="parseInt(product_page) - 3 > 0"><a class="page-link" v-on:click="click_pagination_page(parseInt(product_page) - 3)">{{ parseInt(product_page) - 3 }}</a></li>
                  <li class="page-item" v-if="parseInt(product_page) - 2 > 0"><a class="page-link" v-on:click="click_pagination_page(parseInt(product_page) - 2)">{{ parseInt(product_page) - 2 }}</a></li>
                  <li class="page-item" v-if="parseInt(product_page) - 1 > 0"><a class="page-link" v-on:click="click_pagination_page(parseInt(product_page) - 1)">{{ parseInt(product_page) - 1 }}</a></li>
                  <li class="page-item active"><a class="page-link" v-on:click="click_pagination_page(product_page)">{{ product_page }}</a></li>
                  <li class="page-item" v-if="parseInt(product_page) + 1 <= product_page_total"><a class="page-link"  v-on:click="click_pagination_page(parseInt(product_page) + 1)">{{ parseInt(product_page) + 1 }}</a></li>
                  <li class="page-item" v-if="parseInt(product_page) + 2 <= product_page_total"><a class="page-link"  v-on:click="click_pagination_page(parseInt(product_page) + 2)">{{ parseInt(product_page) + 2}}</a></li>
                  <li class="page-item" v-if="parseInt(product_page) + 3 <= product_page_total"><a class="page-link"  v-on:click="click_pagination_page(parseInt(product_page) + 3)">{{ parseInt(product_page) + 3}}</a></li>
                  <li class="page-item" v-if="parseInt(product_page) + 4 <= product_page_total"><a class="page-link"  v-on:click="click_pagination_page(parseInt(product_page) + 4)">{{ parseInt(product_page) + 4}}</a></li>
                  <li class="page-item" v-if="product_page < product_page_total"><a class="page-link" v-on:click="click_pagination_page(product_page_total)">{{ '>' }}</a></li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
const axios = require('axios');
const Swal = require('sweetalert2');

export default {
  name: 'ProductsComponent',
  data() {
    return {
      category_id: null,
      category_name: "",
      category_description: "",
      category_limit: 12,
      category_page: 1,
      category_page_total: 1,
      product_limit: 12,
      product_page: 1,
      product_page_total: 1,
      categories: [],
      products: []
    }
  },
  async created () {
    await this.get_category_list();
    await this.get_product_list();
  },
  methods: {
    async get_category_list() {
      let api = axios.create({
        timeout: 60 * 1000, // 1 minute
        // headers: {
        //     'Content-Type': 'application/json',
        //     'Access-Control-Allow-Origin': '*',
        //     'Access-Control-Allow-Methods': 'GET, PUT, POST, PATCH, DELETE, OPTIONS',
        //     'Access-Control-Allow-Headers': '*'
        // },
      });

      await api.get('https://vmplastic.com/category_list.php')
        .then(function (response) {
          if (response.data.data) {
            console.log(response.data.data);
            this.categories = response.data.data;
            if (this.categories.length > 0) {
              this.category_id = this.categories[0].id;
              this.click_category(this.category_id);
            }
          } else {
            console.log('error');
              Swal.fire(
              'Error',
              '',
              'error'
            );
          }  
        }.bind(this))
        .catch(function (error) {
          console.error(error);
          Swal.fire(
            'Error',
            '',
            'error'
          )
        })
        .finally(function () {
          // always executed
        });
    },
    async get_product_list() {
      let api = axios.create({
        timeout: 60 * 1000, // 1 minute
        // headers: {
        //     'Content-Type': 'application/json',
        //     'Access-Control-Allow-Origin': '*',
        //     'Access-Control-Allow-Methods': 'GET, PUT, POST, PATCH, DELETE, OPTIONS',
        //     'Access-Control-Allow-Headers': '*'
        // },
      });

      await api.get('https://vmplastic.com/product_list.php?category_id='+this.category_id+'&limit='+this.product_limit+'&page='+this.product_page)
        .then(function (response) {
          if (response.data.data) {
            console.log(response.data.data);
            this.products = response.data.data;
            this.product_page = response.data.page_index;
            this.product_page_total = response.data.page_total;
            this.product_limit = response.data.item_per_page;
          } else {
            console.log('error');
              Swal.fire(
              'Error',
              '',
              'error'
            );
          }  
        }.bind(this))
        .catch(function (error) {
          console.error(error);
          Swal.fire(
            'Error',
            '',
            'error'
          )
        })
        .finally(function () {
          // always executed
        });
    },
    async click_category(category_id) {
      this.category_id = category_id;

      for (var i = 0; i < this.categories.length; i++) {
        if (this.categories[i].id == category_id) {
          this.category_name = this.categories[i].name;
          this.category_description = this.categories[i].description;
        }
      }

      await this.get_product_list();
      //this.$router.push({name: 'ProductsComponent', query: { category_id: category_id }})
    },
    async click_pagination_page(page) {
      this.product_page = page;
      await this.get_product_list();
      //this.$router.push({name: 'AdminCategoryComponent', query: { limit: this.limit, page: page }})
    },
    async click_pagination_next(value) {
      this.product_page = this.product_page + value;
      await this.get_product_list();
      //this.$router.push({name: 'AdminCategoryComponent', query: { limit: this.limit, page: this.page + value }})
    },
    async click_pagination_prev(value) {
      this.product_page = this.product_page - value;
      await this.get_product_list();
      //this.$router.push({name: 'AdminCategoryComponent', query: { limit: this.limit, page: this.page - value }})
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .page-section {
    padding: 2rem 0 4rem 0;
  }
  .products .card {
    background-color: #F2F2F2;
    border: none;
    margin-bottom: 15px;
    border-radius: 15px;
  }

  .products .card .category-image {
    height: 200px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .category-item {
    cursor: pointer;
  }

  .product-image {
    /* border: 1px solid #cccccc; */
    height: 200px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .product-image img {
    max-height: 180px;
  }

  .pagination .page-item .page-link {
    cursor: pointer;
    border: none;
  }
  .pagination li {
    border-radius: 10px;
    background-color: #777777;
    margin-right: 3px;
  }
  .pagination li.active {
    border-radius: 10px;
    background-color: #7757A5;
  }
  .pagination li a {
    color: #000000;
  }
  .pagination .page-item .page-link {
    background-color: transparent;
  }
  .pagination .page-item .page-link:hover {
    background-color: transparent;
  }
</style>
<template>
    <!-- Content Header (Page header) -->
    <div class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
            <div class="col-12">
                <h1 class="m-0" v-if="this.currentRouteName == 'AdminMainComponent'">Dashboard</h1>
                <h1 class="m-0" v-if="this.currentRouteName == 'AdminProductComponent'">Products</h1>
                <h1 class="m-0" v-if="this.currentRouteName == 'AdminCategoryComponent'">Category</h1>
                <h1 class="m-0" v-if="this.currentRouteName == 'AdminBannerComponent'">Banner</h1>
                <h1 class="m-0" v-if="this.currentRouteName == 'AdminPromotionComponent'">Promotion</h1>
            </div>
            <!-- /.col -->
            </div>
            <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->
</template>

<script>
export default {
  name: 'AdminContentHeaderSection',
  computed: {
    currentRouteName() {
      return this.$route.name;
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
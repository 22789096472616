<template>
  <div class="main login-page">
    <div class="login-box">
      <div class="login-logo">
        <b>Admin</b>
      </div>
      <div class="card">
        <div class="card-body login-card-body">
          <p class="login-box-msg">Sign in to start your session</p>
          <div class="input-group mb-3">
            <input type="email" class="form-control" placeholder="Email" v-model="form.email">
            <div class="input-group-append">
              <div class="input-group-text">
                <span class="fas fa-envelope"></span>
              </div>
            </div>
          </div>
          <div class="input-group mb-3">
            <input type="password" class="form-control" placeholder="Password" v-model="form.password">
            <div class="input-group-append">
              <div class="input-group-text">
                <span class="fas fa-lock"></span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <button type="button" class="btn btn-primary btn-block" v-on:click="submit()">Sign In</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
 
import router from "@/router";

const axios = require('axios')
const Swal = require('sweetalert2')

export default {
  name: 'AdminLoginComponent',
  data() {
    return {
      form: {
        email: "",
        password: ""
      }
    }
  },
  methods: {
    clear() {
      this.form = {
        email: "",
        password: ""
      }
    },
    async submit() {
      let api = axios.create({
        timeout: 60 * 1000, // 1 minute
        // headers: {
        //     'Content-Type': 'application/json',
        //     'Access-Control-Allow-Origin': '*',
        //     'Access-Control-Allow-Methods': 'GET, PUT, POST, PATCH, DELETE, OPTIONS',
        //     'Access-Control-Allow-Headers': '*'
        // },
      });

      await api.post('https://vmplastic.com/login.php', {username: this.form.email, password: this.form.password})
        .then(function (response) {
          if (response.data.data) {
            console.log(response.data.data);
            localStorage.setItem('token', response.data.data.token)
            this.get_profile();
          } else {
            console.log('error');
            Swal.fire(
              'Error',
              response.data.message,
              'error'
            );
          }  
        }.bind(this))
        .catch(function (error) {
          console.error(error);
          Swal.fire(
            'Error',
            '',
            'error'
          )
        })
        .finally(function () {
          // always executed
        });
    },
    async get_profile() {
      let api = axios.create({
        timeout: 60 * 1000, // 1 minute
        // headers: {
        //     'Content-Type': 'application/json',
        //     'Access-Control-Allow-Origin': '*',
        //     'Access-Control-Allow-Methods': 'GET, PUT, POST, PATCH, DELETE, OPTIONS',
        //     'Access-Control-Allow-Headers': '*'
        // },
      });

      await api.get('https://vmplastic.com/profile.php', {
          headers: {
            'Authorization': localStorage.getItem('token')
          }
        })
        .then(function (response) {
          if (response.data.data) {
            console.log(response.data.data);
            router.push({ name: 'AdminMainComponent', params: { } })
          } else {
            console.log('error');
            Swal.fire(
              'Error',
              response.data.message,
              'error'
            );
          }  
        }.bind(this))
        .catch(function (error) {
          console.error(error);
          Swal.fire(
            'Error',
            '',
            'error'
          )
        })
        .finally(function () {
          // always executed
        });
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
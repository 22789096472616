<template>
  <div class="home">
    <section class="page-section main" id="main">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="row mb-3">
              <label class="title-ping"><h2><strong>OUR STORY</strong></h2></label>
              <label class="title-ping"><strong>บริษัท วี.เอ็ม. ผลิตภัณฑ์พลาสติก จำกัด</strong></label>
            </div>
            <div class="row mb-3">
              <label>
                โรงงานผู้ผลิตและจำหน่ายบรรจุภัณฑ์หลากหลายแบบ<br/>
                สินค้าของทางร้านมีทั้งที่ผลิตในประเทศไทยและนำเข้าจากต่างประเทศ<br/>
                ไม่ว่าจะเป็นผลิตภัณฑ์พลาสติก, อะคริลิค ตั้งแต่ขวด, กระปุก, หลอด<br/>
                ไปจนถึงงานสกรีนลงบนสินค้า ออกแบบและพิมพ์สติ๊กเกอร์และกล่อง<br/>
                ด้วยประสบการณ์ยาวนานกว่า 28 ปีในธุรกิจ<br/>
                ทำให้ทางร้านสามารถแนะนำบรรจุภัณฑ์ที่เหมาะสมกับสินค้าได้เป็นอย่างดี
              </label>
            </div>
            <div class="row mb-3">
              <label><a href="/about"><button type="button" class="btn btn-secondary">อ่านต่อ</button></a></label>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12">
            <img :src="'assets/main_story.jpg?t='+Date.now()" class="img-fluid">
          </div>
        </div>
      </div>
    </section>

    <section class="page-section title" id="title">
      <div class="container-fluid text-center">
        <h1><strong>โรงงานผลิตและออกแบบบรรจุภัณฑ์</strong></h1>
      </div>
    </section>

    <section class="page-section service" id="service">
      <div class="container">
        <h2 class="text-center mb-0"><strong>บริการ</strong></h2>
        <div class="divider-custom">
          <div class="divider-custom-line"></div>
          <div class="divider-custom-icon">
            <i class="fa-solid fa-star"></i>
          </div>
          <div class="divider-custom-line"></div>
        </div>
        <div class="row mt-5 mb-5">
          <div class="col-sm-12 col-md-3 col-lg-3 text-center mb-5">
            <div class="row">
              <div class="col-12 text-center">
                <p class="circle"><img src="assets/service1.png" class="img-fluid"></p>
              </div>
            </div>
            <div class="row">
              <div class="col-12 text-center">
                รับออกแบบดีไซด์<br />ตามความต้องการของลูกค้า
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-3 col-lg-3 text-center mb-5">
            <div class="row">
              <div class="col-12 text-center">
                <p class="circle"><img src="assets/service2.png" class="img-fluid"></p>
              </div>
            </div>
            <div class="row">
              <div class="col-12 text-center">
                รับผลิตสินค้า<br />ภายใต้แบรนด์ OEM ทุกชนิด
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-3 col-lg-3 text-center mb-5">
            <div class="row">
              <div class="col-12 text-center">
                <p class="circle"><img src="assets/service3.png" class="img-fluid"></p>
              </div>
            </div>
            <div class="row">
              <div class="col-12 text-center">
                ทีมงานขายพร้อม<br />ให้คำแนะนำ ตลอดเวลา 24 ชัวโมง
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-3 col-lg-3 text-center mb-5">
            <div class="row">
              <div class="col-12 justify-content-center">
                <p class="circle"><img src="assets/service4.png" class="img-fluid"></p>
              </div>
            </div>
            <div class="row">
              <div class="col-12 text-center">
                บริการครบจบ<br />One Stop Service ที่นี้ !!
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="page-section about" id="about">
      <div class="container">
        <AboutSection />
      </div>
    </section>

    <section class="page-section category" id="category">
      <div class="container">
        <h2 class="text-center mb-0"><strong>หมวดหมู่สินค้า</strong></h2>
        <div class="divider-custom">
          <div class="divider-custom-line"></div>
          <div class="divider-custom-icon">
            <i class="fa-solid fa-star"></i>
          </div>
          <div class="divider-custom-line"></div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-4 col-lg-4 text-center" 
            v-for="(item, index) in categories" :key="index" v-on:click="click_category(item.id)">
            <div class="card" :class="{'category-item-active':item.id == category_id}">
              <div class="row mb-3">
                <div class="col-12 justify-content-center category-image">
                  <img :src="item.image" class="img-fluid">
                </div>
              </div>
              <div class="row">
                <div class="col-12 text-center">
                  <strong>{{ item.name }}</strong>
                </div>
              </div>
              <div class="row">
                <div class="col-12 text-center">
                  <p>{{ item.description }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="page-section product" id="product" v-if="category_id != null">
      <div class="container">
        <div class="row">
          <div class="col-12 text-center">
            <h1><strong>{{ category_name }}</strong></h1>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-center">
            {{ category_description }}
          </div>
        </div>
        <div class="row" v-if="products.length > 0">
          <div class="col-sm-6 col-md-6 col-lg-4 text-center mb-3 mt-3" v-for="(item, index) in products" :key="index">
            <div class="row mb-3 product-image">
              <div class="col-12 justify-content-center">
                <img :src="'/'+item.image" class="img-fluid">
              </div>
            </div>
            <div class="row">
              <div class="col-12 text-center">
                <strong>{{ item.name }}</strong>
              </div>
            </div>
            <div class="row">
              <div class="col-12 text-center">
                {{ item.description }}
              </div>
            </div>
            <div class="row">
              <div class="col-12 text-center">
                {{ item.price }} ฿
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 d-flex justify-content-center">
              <nav aria-label="Page navigation">
                <ul class="pagination">
                  <li class="page-item" v-if="product_page > 1"><a class="page-link" v-on:click="click_products_pagination_page(1)">{{ '<' }}</a></li>
                  <li class="page-item" v-if="parseInt(product_page) - 4 > 0"><a class="page-link" v-on:click="click_products_pagination_page(parseInt(product_page) - 4)">{{ parseInt(product_page) - 4 }}</a></li>
                  <li class="page-item" v-if="parseInt(product_page) - 3 > 0"><a class="page-link" v-on:click="click_products_pagination_page(parseInt(product_page) - 3)">{{ parseInt(product_page) - 3 }}</a></li>
                  <li class="page-item" v-if="parseInt(product_page) - 2 > 0"><a class="page-link" v-on:click="click_products_pagination_page(parseInt(product_page) - 2)">{{ parseInt(product_page) - 2 }}</a></li>
                  <li class="page-item" v-if="parseInt(product_page) - 1 > 0"><a class="page-link" v-on:click="click_products_pagination_page(parseInt(product_page) - 1)">{{ parseInt(product_page) - 1 }}</a></li>
                  <li class="page-item active"><a class="page-link" v-on:click="click_products_pagination_page(product_page)">{{ product_page }}</a></li>
                  <li class="page-item" v-if="parseInt(product_page) + 1 <= product_page_total"><a class="page-link"  v-on:click="click_products_pagination_page(parseInt(product_page) + 1)">{{ parseInt(product_page) + 1 }}</a></li>
                  <li class="page-item" v-if="parseInt(product_page) + 2 <= product_page_total"><a class="page-link"  v-on:click="click_products_pagination_page(parseInt(product_page) + 2)">{{ parseInt(product_page) + 2}}</a></li>
                  <li class="page-item" v-if="parseInt(product_page) + 3 <= product_page_total"><a class="page-link"  v-on:click="click_products_pagination_page(parseInt(product_page) + 3)">{{ parseInt(product_page) + 3}}</a></li>
                  <li class="page-item" v-if="parseInt(product_page) + 4 <= product_page_total"><a class="page-link"  v-on:click="click_products_pagination_page(parseInt(product_page) + 4)">{{ parseInt(product_page) + 4}}</a></li>
                  <li class="page-item" v-if="product_page < product_page_total"><a class="page-link" v-on:click="click_products_pagination_page(product_page_total)">{{ '>' }}</a></li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="page-section customer" id="customer">
      <div class="container">
        <h2 class="text-center mb-0"><strong>ลูกค้าของเรา</strong></h2>
        <div class="divider-custom">
          <div class="divider-custom-line"></div>
          <div class="divider-custom-icon">
            <i class="fa-solid fa-star"></i>
          </div>
          <div class="divider-custom-line"></div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-4 col-lg-4 text-center" v-for="(item, index) in customers" :key="index">
            <div class="row">
              <div class="col-12 text-center">
                <p><img :src="item.image" class="img-fluid"></p>
              </div>
            </div>
            <div class="row">
              <div class="col-12 text-center">
                <p v-html="item.desciption"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="page-section contact" id="contact">
      <div class="container">
        <h2 class="text-center mb-0"><strong>ติดต่อเรา</strong></h2>
        <div class="divider-custom">
          <div class="divider-custom-line"></div>
          <div class="divider-custom-icon">
            <i class="fa-solid fa-envelope"></i>
          </div>
          <div class="divider-custom-line"></div>
        </div>
        <div class="row justify-content-center">
          <div class="col-sm-12 col-md-8 col-lg-8">
            <form>
              <div class="row">
                <div class="mb-3">
                  <input type="text" class="form-control" id="exampleInputName" v-model="form.name" placeholder="ชื่อ - นามสกุล">
                </div>
                <div class="mb-3">
                  <input type="text" class="form-control" id="exampleInputPhone" v-model="form.phone" placeholder="เบอร์โทร">
                </div>
                <div class="mb-3">
                  <input type="email" class="form-control" id="exampleInputEmail" v-model="form.email" placeholder="อีเมล์">
                </div>
                <div class="mb-3">
                  <input type="text" class="form-control" id="exampleInputMessage" v-model="form.message" placeholder="ข้อความ">
                </div>
              </div>
              <div class="row">
                <div class="col-12 text-center">
                  <button type="button" class="btn btn-primary" v-on:click="submit()">ส่งข้อมูล</button>
                  &nbsp;
                  <button type="button" class="btn btn-secondary" v-on:click="clear()">ยกเลิก</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>

    <section class="map" id="map">
      <div class="container-fluid">
        <h2 class="text-center mb-0"><strong>แผนที่</strong></h2>
        <div class="divider-custom">
          <div class="divider-custom-line"></div>
          <div class="divider-custom-icon">
            <i class="fa-solid fa-star"></i>
          </div>
          <div class="divider-custom-line"></div>
        </div>
        <div class="row">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1938.691502498858!2d100.28559570679666!3d13.634450522541286!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e2bf9000000015%3A0x6ad9ce8b71db7bde!2z4Lia4Lij4Li04Lip4Lix4LiXIOC4p-C4tS7guYDguK3guYfguKEuIOC4nOC4peC4tOC4leC4oOC4seC4k-C4keC5jOC4nuC4peC4suC4quC4leC4tOC4gSDguIjguLPguIHguLHguJQgKOC4quC4s-C4meC4seC4geC4h-C4suC4meC5g-C4q-C4jeC5iCk!5e0!3m2!1sen!2sth!4v1692704689080!5m2!1sen!2sth" 
            width="1920" height="450" 
            style="border:0;" allowfullscreen="" loading="lazy" 
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import AboutSection from './../sections/AboutSection.vue'

const axios = require('axios');
const Swal = require('sweetalert2')

export default {
  name: 'HomeComponent',
  components: {
    AboutSection
  },
  data() {
    return {
      category_id: null,
      products: [],
      product_limit: 12,
      product_page: 1,
      product_page_total: 1,
      form: {
        name: "",
        phone: "",
        email: "",
        message: ""
      },
      categories: [],
      customers: [
        {
          image: "assets/customer1.png",
          desciption: "ได้รับสินค้ารวดเร็วมาก บริการก่อนการขายยอดเยี่ยม<br/>บริการหลังการขายก็เช่นกัน ขอบคุณค่ะ"
        },
        {
          image: "assets/customer2.png",
          desciption: "ผลิตภัณฑ์ที่คุณให้มานี้เป็นเรื่องยอดเยี่ยมจริงๆ<br/>ฉันมีประสบการณ์ที่ดีมากๆ"
        },
        {
          image: "assets/customer3.png",
          desciption: "ขอบคุณทีมงานสำหรับการช่วยเหลือที่ดีมาก<br/>ผลิตภัณฑ์ที่ฉันผลิตเป็นที่ตรงตามความต้องการและคุณภาพดีมาก"
        },
        {
          image: "assets/customer4.png",
          desciption: "การออกแบบผลิตภัณฑ์<br/>มันดูงดงามและมีความคิดสร้างสรรค์"
        },
        {
          image: "assets/customer5.png",
          desciption: "ประทับใจในความรวดเร็ว<br/>ทีมงานของคุณเข้าใจความต้องการของเรา"
        },
        {
          image: "assets/customer6.png",
          desciption: "ไม่เคยพบการบริการที่ดีแบบนี้มาก่อน!<br/>ทีมงานคุณให้คำแนะนำและช่วยเหลืออย่างเต็มที่ความอัธยาศัยดีมาก"
        },
      ]
    }
  },
  async created () {
    await this.get_category_list();
  },
  methods: {
    clear() {
      this.form = {
        name: "",
        phone: "",
        email: "",
        message: ""
      }
    },
    async get_category_list() {
      let api = axios.create({
        timeout: 60 * 1000, // 1 minute
        // headers: {
        //     'Content-Type': 'application/json',
        //     'Access-Control-Allow-Origin': '*',
        //     'Access-Control-Allow-Methods': 'GET, PUT, POST, PATCH, DELETE, OPTIONS',
        //     'Access-Control-Allow-Headers': '*'
        // },
      });

      await api.get('https://vmplastic.com/category_list.php')
        .then(function (response) {
          if (response.data.data) {
            this.categories = response.data.data;
            if (this.categories.length > 0) {
              this.category_id = this.categories[0].id;
              this.click_category(this.category_id);
            }
          } else {
            console.log('error');
              Swal.fire(
              'Error',
              '',
              'error'
            );
          }  
        }.bind(this))
        .catch(function (error) {
          console.error(error);
          Swal.fire(
            'Error',
            '',
            'error'
          )
        })
        .finally(function () {
          // always executed
        });
    },
    async submit() {
      if (this.form.name != "" && this.form.phone != "" && this.form.email != "" && this.form.message != "") {
        let message = "\nข้อความแจ้งเตือนจาก\n";
        message += "ชื่อ " + this.form.name + "\n"
        message += "เบอร์โทรศัพท์ " + this.form.phone + "\n"
        message += "อีเมล์ " + this.form.email + "\n"
        message += "ข้อความ " + this.form.message

        let api = axios.create({
          timeout: 60 * 1000, // 1 minute
          headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Allow-Methods': 'GET, PUT, POST, PATCH, DELETE, OPTIONS',
              'Access-Control-Allow-Headers': '*'
          },
        });

        await api.post(
          'https://vmplastic.com/contact.php',
          { message: message }
        )
        .then(function (response) {
          if (response.data.status == 200 && response.data.message == "ok") {
            Swal.fire(
              'Success',
              '',
              'success'
            )
            this.clear()
          } else {
            console.log('error')
          }
        }.bind(this))
        .catch(function (error) {
          console.error(error);
          Swal.fire(
              'Error',
              '',
              'error'
            )
        })
        .finally(function () {
          // always executed
        });
      }
    },
    async click_category(category_id) {
      this.category_id = category_id;

      for (var i = 0; i < this.categories.length; i++) {
        if (this.categories[i].id == category_id) {
          this.category_name = this.categories[i].name;
          this.category_description = this.categories[i].description;
        }
      }

      await this.get_product_list();
      //this.$router.push({name: 'ProductsComponent', query: { category_id: category_id }})
    },
    async get_product_list() {
      let api = axios.create({
        timeout: 60 * 1000, // 1 minute
        // headers: {
        //     'Content-Type': 'application/json',
        //     'Access-Control-Allow-Origin': '*',
        //     'Access-Control-Allow-Methods': 'GET, PUT, POST, PATCH, DELETE, OPTIONS',
        //     'Access-Control-Allow-Headers': '*'
        // },
      });

      await api.get('https://vmplastic.com/product_list.php?category_id='+this.category_id+'&limit='+this.product_limit+'&page='+this.product_page)
        .then(function (response) {
          if (response.data.data) {
            console.log(response.data.data);
            this.products = response.data.data;
            this.product_page = response.data.page_index;
            this.product_page_total = response.data.page_total;
            this.product_limit = response.data.item_per_page;
          } else {
            console.log('error');
              Swal.fire(
              'Error',
              '',
              'error'
            );
          }  
        }.bind(this))
        .catch(function (error) {
          console.error(error);
          Swal.fire(
            'Error',
            '',
            'error'
          )
        })
        .finally(function () {
          // always executed
        });
    },
    async click_products_pagination_page(page) {
      this.product_page = page;
      await this.get_product_list();
      //this.$router.push({name: 'AdminCategoryComponent', query: { limit: this.limit, page: page }})
    },
    async click_products_pagination_next(value) {
      this.product_page = this.product_page + value;
      await this.get_product_list();
      //this.$router.push({name: 'AdminCategoryComponent', query: { limit: this.limit, page: this.page + value }})
    },
    async click_products_pagination_prev(value) {
      this.product_page = this.product_page - value;
      await this.get_product_list();
      //this.$router.push({name: 'AdminCategoryComponent', query: { limit: this.limit, page: this.page - value }})
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  form input {
    border-radius: 40px;
    height: 50px;
    padding: 25px 30px;
  }
  button {
    width: 150px;
    max-width: 150px;
  }
  .category .card {
    background-color: #F2F2F2;
    border: none;
    margin-bottom: 15px;
    border-radius: 15px;
  }

  .category .card .category-image {
    height: 200px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  /* .category .card .category-image img {
    margin: 0;
    position: absolute;
    top: 0%;
    left: 0%;
  } */
  .category .img-fluid {
    max-width: 200px;
  }

  .title {
    background-color: #7757A5;
    color: #FFFFFF;
    padding: 2rem 0 !important;
  }
</style>

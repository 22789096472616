import { createRouter, createWebHistory } from 'vue-router'
import HomeComponent from '@/components/HomeComponent.vue'
import ProductsComponent from '@/components/ProductsComponent.vue'
import PromotionComponent from '@/components/PromotionComponent.vue'
import PromotionDetailComponent from '@/components/PromotionDetailComponent.vue'
import AboutComponent from '@/components/AboutComponent.vue'

import AdminMainComponent from '@/components/admin/MainComponent.vue'
import AdminProfileComponent from '@/components/admin/ProfileComponent.vue'
import AdminChangePasswordComponent from '@/components/admin/ChangePasswordComponent.vue'
import AdminLoginComponent from '@/components/admin/LoginComponent.vue'
import AdminProductComponent from '@/components/admin/ProductComponent.vue'
import AdminCategoryComponent from '@/components/admin/CategoryComponent.vue'
import AdminBannerComponent from '@/components/admin/BannerComponent.vue'
import AdminUserComponent from '@/components/admin/UserComponent.vue'
import AdminPromotionComponent from '@/components/admin/PromotionComponent.vue'
import AdminProductUpdateComponent from '@/components/admin/ProductUpdateComponent.vue'
import AdminCategoryUpdateComponent from '@/components/admin/CategoryUpdateComponent.vue'
import AdminBannerUpdateComponent from '@/components/admin/BannerUpdateComponent.vue'
import AdminPromotionUpdateComponent from '@/components/admin/PromotionUpdateComponent.vue'
import AdminUserUpdateComponent from '@/components/admin/UserUpdateComponent.vue'

const routes = [
    {
        path: '/',
        name: 'HomeComponent',
        component: HomeComponent
    },
    {
        path: '/products',
        name: 'ProductsComponent',
        component: ProductsComponent
    },
    {
        path: '/promotion',
        name: 'PromotionComponent',
        component: PromotionComponent
    },
    {
        path: '/promotion/:id',
        name: 'PromotionDetailComponent',
        component: PromotionDetailComponent
    },
    {
        path: '/about',
        name: 'AboutComponent',
        component: AboutComponent
    },
    {
        path: '/admin',
        name: 'AdminLoginComponent',
        component: AdminLoginComponent
    },
    {
        path: '/admin/dashboard',
        name: 'AdminMainComponent',
        component: AdminMainComponent
    },
    {
        path: '/admin/profile',
        name: 'AdminProfileComponent',
        component: AdminProfileComponent
    },
    {
        path: '/admin/change-password',
        name: 'AdminChangePasswordComponent',
        component: AdminChangePasswordComponent
    },
    {
        path: '/admin/category',
        name: 'AdminCategoryComponent',
        component: AdminCategoryComponent
    },
    {
        path: '/admin/category/create',
        name: 'AdminCategoryCreateComponent',
        component: AdminCategoryUpdateComponent
    },
    {
        path: '/admin/category/:id/edit',
        name: 'AdminCategoryUpdateComponent',
        component: AdminCategoryUpdateComponent
    },
    {
        path: '/admin/product',
        name: 'AdminProductComponent',
        component: AdminProductComponent
    },
    {
        path: '/admin/product/create',
        name: 'AdminProductCreateComponent',
        component: AdminProductUpdateComponent
    },
    {
        path: '/admin/product/:id/edit',
        name: 'AdminProductUpdateComponent',
        component: AdminProductUpdateComponent
    },
    {
        path: '/admin/banner',
        name: 'AdminBannerComponent',
        component: AdminBannerComponent
    },
    {
        path: '/admin/banner/create',
        name: 'AdminBannerCreateComponent',
        component: AdminBannerUpdateComponent
    },
    {
        path: '/admin/banner/:id/edit',
        name: 'AdminBannerUpdateComponent',
        component: AdminBannerUpdateComponent
    },
    {
        path: '/admin/promotion',
        name: 'AdminPromotionComponent',
        component: AdminPromotionComponent
    },
    {
        path: '/admin/promotion/create',
        name: 'AdminPromotionCreateComponent',
        component: AdminPromotionUpdateComponent
    },
    {
        path: '/admin/promotion/:id/edit',
        name: 'AdminPromotionUpdateComponent',
        component: AdminPromotionUpdateComponent
    },
    {
        path: '/admin/user',
        name: 'AdminUserComponent',
        component: AdminUserComponent
    },
    {
        path: '/admin/user/create',
        name: 'AdminUserCreateComponent',
        component: AdminUserUpdateComponent
    },
    {
        path: '/admin/user/:id/edit',
        name: 'AdminUserUpdateComponent',
        component: AdminUserUpdateComponent
    }
]

const router = createRouter({ history: createWebHistory(), routes })
export default router
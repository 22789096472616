<template>
  <div class="about" id="about">
    <h2 class="text-center mb-0"><strong>เกี่ยวกับเรา</strong></h2>
    <div class="divider-custom">
      <div class="divider-custom-line"></div>
      <div class="divider-custom-icon">
        <i class="fa-solid fa-star"></i>
      </div>
      <div class="divider-custom-line"></div>
    </div>
    <div class="row mt-5 mb-5">
      <div class="col-sm-12 col-md-12 col-lg-6 pl-5 pr-5">
        <div class="row mb-3">
          <label class="title-ping"><h2><strong>OUR STORY</strong></h2></label>
          <label class="title-ping"><strong>บริษัท วี.เอ็ม. ผลิตภัณฑ์พลาสติก จำกัด</strong></label>
        </div>
        <div class="row mb-3">
          <label>
            โรงงานผู้ผลิตและจำหน่ายบรรจุภัณฑ์หลากหลายแบบ<br/>
            สินค้าของทางร้านมีทั้งที่ผลิตในประเทศและนำเข้าจากต่างประเทศ<br/>
            ไม่ว่าจะเป็นผลิตภัณฑ์พลาสติค, อะคริลิค ตั้งแต่ขวด, กระปุก, หลอด<br/>
            ไปจนถึงงานสกรีนลงบนสินค้า ออกแบบและพิมพ์สติ๊กเกอร์และกล่อง<br/>
            ด้วยประสบการณ์ยาวนานกว่า 28 ปี ในธุรกิจ<br/>
            ทำให้ทางร้านสามารถแนะนำบรรจุภัณฑ์ที่เหมาะสมกับสินค้าได้เป็นอย่างดี
          </label>
        </div>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-6">
        <img src="assets/about1.jpg" class="img-fluid">
      </div>
    </div>
    <div class="row mt-5 mb-5">
      <div class="col-sm-12 col-md-12 col-lg-6">
        <img src="assets/about2.jpg" class="img-fluid">
      </div>
      <div class="col-sm-12 col-md-12 col-lg-6 pl-5 pr-5">
        <div class="row mb-3">
          <label class="title-ping"><h2><strong>V.M.PLASTIC</strong></h2></label>
          <label class="title-ping"><strong>บริษัท วี.เอ็ม. ผลิตภัณฑ์พลาสติก จำกัด</strong></label>
        </div>
        <div class="row mb-3">
          <label>
            เราจำหน่ายและนำเข้าบรรจุภัณฑ์เครื่องสำอาง<br/>
            และเวชภัณฑ์ทุกชนิด เช่น กระปุกครีม หลอดครีม ขวดครี<br/>
            เรามีรูปแบบของบรรจุภัณฑ์หลากหลายกว่า 1,500 รายการ<br/>
            จำหน่ายทั้งปลีกและส่ง ด้วยราคาจากโรงงานโดยตรง<br/>
            <br/>
            ตอบสนองความต้องการของลูกค้าที่จะนำไปต่อยอดสินค้าของแต่ละท่าน<br/>
            เพื่อสร้างความน่าสนใจ และเพิ่มมูลค่าของสินค้าที่อยู่ด้านในได้อย่างลงตัว<br/>
            เราเชื่อว่า หากลูกค้าได้ข้อมูลที่เพียงพอ จะนำมาซึ่งการตัดสินใจที่ถูกต้อง<br/>
            ในการเลือกใช้บรรจุภัณฑ์ให้เหมาะกับสินค้า และเหมาะกับกลุ่มเป้าหมายของสินค้า
          </label>
        </div>
      </div>
    </div>
    <div class="row mt-5 mb-5">
      <div class="col-sm-12 col-md-12 col-lg-6 pl-5 pr-5">
        <div class="row mb-3">
          <label class="title-ping"><h2><strong>OUR PEOPLE</strong></h2></label>
          <label class="title-ping"><strong>บริษัท วี.เอ็ม. ผลิตภัณฑ์พลาสติก จำกัด</strong></label>
        </div>
        <div class="row mb-3">
          <label>
            เพื่อพัฒนาพนักงานให้บุคลากรที่มีคุณภาพเพื่อขับเคลื่อนองค์กรให้ก้าว<br/>
            สู่ความเป็นผู้นำด้านผลิต  มีความรู้และทักษะที่เกี่ยวข้องกับงานของตนจะมีคุณภาพที่ดี
          </label>
        </div>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-6">
        <img src="assets/about3.jpg" class="img-fluid">
      </div>
    </div>
    <div class="row mt-5 mb-5">
      <div class="col-sm-12 col-md-12 col-lg-6 pl-5 pr-5">
        <img src="assets/about4.jpg" class="img-fluid">
      </div>
      <div class="col-sm-12 col-md-12 col-lg-6">
        <div class="row mb-3">
          <label class="title-ping"><h2><strong>OUR PRODUCT</strong></h2></label>
          <label class="title-ping"><strong>บริษัท วี.เอ็ม. ผลิตภัณฑ์พลาสติก จำกัด</strong></label>
        </div>
        <div class="row mb-3">
          <label>
            สินค้าทุกชิ้นคุณภาพจะต้องปลอดภัยในการใช้งานและมีความถูกต้องตามมาตรฐาน<br/>
            ที่กำหนดไว้ กระบวนการผลิตนั้นเช่นการตรวจสอบคุณภาพระหว่างกระบวนการ<br/>
            การควบคุมการผลิตและการทดสอบสินค้าสามารถช่วยให้ได้ผลิตสินค้าที่มีคุณภาพสูง
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutSection'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
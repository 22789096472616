<template>
  <div class="about">
    <section>
      <div class="container">
        <nav class="mt-4" aria-label="breadcrumb">
          <ol class="breadcrumb bg-color-transparent">
            <li class="breadcrumb-item"><a href="/">HOME</a></li>
            <li class="breadcrumb-item active" aria-current="/about">ABOUT</li>
          </ol>
        </nav>
      </div>
    </section>
    <section class="page-section">
      <div class="container">
        <AboutSection />
      </div>
    </section>
  </div>
</template>

<script>
import AboutSection from './../sections/AboutSection.vue'

export default {
  name: 'AboutComponent',
  components: {
    AboutSection
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .page-section {
    padding: 2rem 0 4rem 0;
  }
</style>
<template>
  <div class="main">
    Admin Main
  </div>
</template>

<script>
export default {
  name: 'AdminMainComponent'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
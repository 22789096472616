<template>
  <div class="promotion">
    <section>
      <div class="container">
        <nav class="mt-4" aria-label="breadcrumb">
          <ol class="breadcrumb bg-color-transparent">
            <li class="breadcrumb-item"><a href="/">HOME</a></li>
            <li class="breadcrumb-item" aria-current="/promotion">PROMOTION</li>
            <li class="breadcrumb-item active" aria-current="/promotion">{{ promotion.title }}</li>
          </ol>
        </nav>
      </div>
    </section>
    <section class="page-section">
      <div class="container">
        <h2 class="text-center mb-0"><strong>{{ promotion.title }}</strong></h2>
        <div class="divider-custom">
          <div class="divider-custom-line"></div>
          <div class="divider-custom-icon">
            <i class="fa-solid fa-star"></i>
          </div>
          <div class="divider-custom-line"></div>
        </div>
        <div class="row mb-3" v-if="promotion.image">
          <div class="col-12 text-center">
            <img :src="'/'+promotion.image" class="img-fluid">
          </div>
        </div>
        <div class="row" v-if="promotion.description">
          <div class="col-12" v-html="promotion.description.replace(/\n/g, '<br />')"></div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { useRoute } from 'vue-router'

const axios = require('axios');
const Swal = require('sweetalert2');

export default {
  name: 'PromotionDetailComponent',
  data() {
    return {
      id: null,
      promotion: {}
    }
  },
  async created () {
    const route = useRoute()

    if (typeof route.params.id != 'undefined') {
      this.id = route.params.id
      await this.get_promotion();
    }
  },
  methods: {
    async get_promotion() {
      let api = axios.create({
        timeout: 60 * 1000, // 1 minute
        // headers: {
        //     'Content-Type': 'application/json',
        //     'Access-Control-Allow-Origin': '*',
        //     'Access-Control-Allow-Methods': 'GET, PUT, POST, PATCH, DELETE, OPTIONS',
        //     'Access-Control-Allow-Headers': '*'
        // },
      });

      await api.get('https://vmplastic.com/promotion_list.php?id='+this.id)
        .then(function (response) {
          if (response.data.data) {
            console.log(response.data.data);
            this.promotion = response.data.data[0];
          } else {
            console.log('error');
              Swal.fire(
              'Error',
              '',
              'error'
            );
          }  
        }.bind(this))
        .catch(function (error) {
          console.error(error);
          Swal.fire(
            'Error',
            '',
            'error'
          )
        })
        .finally(function () {
          // always executed
        });
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .page-section {
    padding: 2rem 0 4rem 0;
  }
  .card {
    background-color: #F2F2F2;
    border: none;
    /* margin-bottom: 15px;
    border-radius: 15px; */
    padding: auto;
    height: 200px;
    margin: auto;
    background-position: center;
  }
  .card img {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
</style>
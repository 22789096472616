<template>
  <NavbarSection v-if="!admin() && this.currentRouteName != 'AdminLoginComponent'"/>
  <BannerSection v-if="!admin() && this.currentRouteName != 'AdminLoginComponent'"/>
  <router-view v-if="!admin() || this.currentRouteName == 'AdminLoginComponent'" />
  <FooterSection v-if="!admin() && this.currentRouteName != 'AdminLoginComponent'"/>

  <div class="wrapper" v-if="admin() && this.currentRouteName != 'AdminLoginComponent'">
    <AdminNavbarSection />
    <AdminSidebarSection />
    
    <div class="content-wrapper">
      <AdminContentHeaderSection />
      <section class="content">
        <div class="container-fluid">
          <router-view />
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import NavbarSection from './sections/NavbarSection.vue'
import BannerSection from './sections/BannerSection.vue'
import FooterSection from './sections/FooterSection.vue'

import AdminNavbarSection from './sections/admin/NavbarSection.vue'
import AdminSidebarSection from './sections/admin/SidebarSection.vue'
import AdminContentHeaderSection from './sections/admin/ContentHeaderSection.vue'

export default {
  name: 'App',
  components: {
    NavbarSection,
    BannerSection,
    FooterSection,
    AdminNavbarSection,
    AdminSidebarSection,
    AdminContentHeaderSection
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    }
  },
  methods: {
    admin() {
      return (
        this.currentRouteName == 'AdminMainComponent' || 
        this.currentRouteName == 'AdminProfileComponent' || 
        this.currentRouteName == 'AdminChangePasswordComponent' || 
        this.currentRouteName == 'AdminProductComponent' || 
        this.currentRouteName == 'AdminProductCreateComponent' || 
        this.currentRouteName == 'AdminProductUpdateComponent' || 
        this.currentRouteName == 'AdminCategoryComponent' ||
        this.currentRouteName == 'AdminCategoryCreateComponent' ||
        this.currentRouteName == 'AdminCategoryUpdateComponent' ||
        this.currentRouteName == 'AdminBannerComponent' ||
        this.currentRouteName == 'AdminBannerCreateComponent' ||
        this.currentRouteName == 'AdminBannerUpdateComponent' ||
        this.currentRouteName == 'AdminPromotionComponent' ||
        this.currentRouteName == 'AdminPromotionCreateComponent' ||
        this.currentRouteName == 'AdminPromotionUpdateComponent' ||
        this.currentRouteName == 'AdminUserComponent' ||
        this.currentRouteName == 'AdminUserCreateComponent' ||
        this.currentRouteName == 'AdminUserUpdateComponent'
      );
    }
  }
}
</script>

<style>
  .page-section {
    padding: 5rem 0;
  }
  /* Button */
  .btn {
    border-radius: 10px !important;
  }
  .btn-primary {
    background-color: #7757A5 !important;
    border-color: #7757A5 !important;
  }
  /* Button */

  /* Title Pink */
  .title-ping {
    color: #7757A5 !important;
  }
  /* Title Pink */

  /* Header Section */
  .divider-custom {
    margin:1.25rem 0 1.5rem;
    width:100%;
    display:flex;
    justify-content:center;
    align-items:center
  }
  .divider-custom .divider-custom-line {
    width:100%;
    max-width:7rem;
    height:.25rem;
    background-color:#2c3e50;
    border-radius:1rem;
    border-color:#2c3e50!important
  }
  .divider-custom .divider-custom-line:first-child {
    margin-right:1rem
  }
  .divider-custom .divider-custom-line:last-child {
    margin-left:1rem
  }
  .divider-custom .divider-custom-icon {
    color:#2c3e50!important;
    font-size:2rem
  }
  .divider-custom.divider-light .divider-custom-line {
    background-color:#fff
  }
  .divider-custom.divider-light .divider-custom-icon {
    color:#fff!important
  }
  /* Header Section */

  /* Breadcrumb Link */
  .breadcrumb-item + .breadcrumb-item::before {
    content: ">" !important;
    padding: 0 12px 0 7px !important;
    font-size: 18px;
    line-height: 1.3;
  }
  .breadcrumb a {
    color: #333333;
    text-decoration: none;
  }
  .breadcrumb .active {
    color: #555555;
  }
  /* Breadcrumb Link */

  .bg-color-transparent {
    background-color: transparent !important;
  }

  .text-overflow-ellipsis {
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis; 
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .category-item-active {
    background-color: #7757A5 !important;
  }
</style>

<template>
  <div class="main">
    <div class="row mb-3">
      <div class="col-12">
        <a href="/admin/user/create" class="btn btn-primary">Create</a>
      </div>
    </div>
    <div class="card">
      <div class="card-header">
        <h3 class="card-title">Users</h3>
        <div class="card-tools">
          <div class="input-group input-group-sm">
            <input type="text" name="table_search" class="form-control float-right" v-model="search"
              placeholder="Search">
            <div class="input-group-append">
              <button type="submit" class="btn btn-default" v-on:click="get_list()">
                <i class="fas fa-search"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>#</th>
              <th>Email</th>
              <th>Name</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in list" :key="index">
              <td>{{ item.id }}</td>
              <td>{{ item.username }}</td>
              <td>{{ item.name }}</td>
              <td>
                <a :href="'/admin/user/'+item.id+'/edit'" class="btn btn-link">Update</a>
                &nbsp;
                <a class="btn btn-link" v-on:click="ask_delete_item(item.id)">Delete</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
  
<script>
import { useRoute } from 'vue-router'

const axios = require('axios');
const Swal = require('sweetalert2');

export default {
  name: 'AdminUserComponent',
  data() {
    return {
      user_id: '',
      limit: 10,
      page: 1,
      list: [],
      page_total: 1,
      item_total: 0,
      search: ''
    }
  },
  async created () {
    const route = useRoute()
    if (typeof route.query.limit != 'undefined') {
      this.limit = route.query.limit
    }
    if (typeof route.query.page != 'undefined') {
      this.page = route.query.page
    }
    await this.get_list();
  },
  methods: {
    click_pagination_page(page) {
      this.$router.push({name: 'AdminUserComponent', query: { limit: this.limit, page: page }})
    },
    click_pagination_next(value) {
      this.$router.push({name: 'AdminUserComponent', query: { limit: this.limit, page: this.page + value }})
    },
    click_pagination_prev(value) {
      this.$router.push({name: 'AdminUserComponent', query: { limit: this.limit, page: this.page - value }})
    },
    async get_list() {
      let api = axios.create({
        timeout: 60 * 1000, // 1 minute
        // headers: {
        //     'Content-Type': 'application/json',
        //     'Access-Control-Allow-Origin': '*',
        //     'Access-Control-Allow-Methods': 'GET, PUT, POST, PATCH, DELETE, OPTIONS',
        //     'Access-Control-Allow-Headers': '*'
        // },
      });

      await api.get('https://vmplastic.com/user_list.php?limit='+this.limit+'&page='+this.page+'&search='+this.search, {
          headers: {
            'Authorization': localStorage.getItem('token')
          }
        })
        .then(function (response) {
          if (response.data.data) {
            console.log(response.data.data);
            this.list = response.data.data;
            this.page = response.data.page_index;
            this.page_total = response.data.page_total;
            this.item_total = response.data.item_total;
            this.limit = response.data.item_per_page;
          } else {
            console.log('error');
              Swal.fire(
              'Error',
              '',
              'error'
            );
          }  
        }.bind(this))
        .catch(function (error) {
          console.error(error);
          Swal.fire(
            'Error',
            '',
            'error'
          )
        })
        .finally(function () {
          // always executed
        });
    },
    async ask_delete_item(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "Are you sure want to delete this item!",
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {
          this.delete_item(id);
        }
      });
    },
    async delete_item(id) {
      let api = axios.create({
        timeout: 60 * 1000, // 1 minute
        // headers: {
        //     'Content-Type': 'application/json',
        //     'Access-Control-Allow-Origin': '*',
        //     'Access-Control-Allow-Methods': 'GET, PUT, POST, PATCH, DELETE, OPTIONS',
        //     'Access-Control-Allow-Headers': '*'
        // },
      });

      let form = new FormData();
      form.append('action', 'delete');
      form.append('id', id);

      await api.post('https://vmplastic.com/user.php', form, {
          headers: {
            'Authorization': localStorage.getItem('token')
          }
        })
        .then(function (response) {
          if (response.data.is_error == false) {
            console.log(response.data);
            if (response.data.is_error == false && response.data.message == null) {
              Swal.fire({
                title: 'Deleted!',
                icon: 'success'
              }).then((result) => {
                if (result.value) {
                  window.location.reload();
                }
              });
            }
          } else {
            console.log('error');
            Swal.fire(
              'Error',
              response.data.message,
              'error'
            );
          }  
        }.bind(this));
    }
  }
}
</script>
  
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
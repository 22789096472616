<template>
  <div class="banner" id="banner">
    <carousel :autoplay="5000" :wrap-around="true">
      <slide v-for="slide in data" :key="slide">
        <div class="carousel__item"><img :src="slide" class="img-fluid"></div>
      </slide>
      <template #addons>
        <navigation />
      </template>
    </carousel>
  </div>
</template>

<script>
const axios = require('axios');
const Swal = require('sweetalert2');

import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Navigation } from 'vue3-carousel'

export default {
  name: 'BannerSection',
  data() {
    return {
      data: [],
    }
  },
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  async created () {
    await this.get_banner_list();
  },
  methods: {
    async get_banner_list() {
      let api = axios.create({
        timeout: 60 * 1000, // 1 minute
        // headers: {
        //     'Content-Type': 'application/json',
        //     'Access-Control-Allow-Origin': '*',
        //     'Access-Control-Allow-Methods': 'GET, PUT, POST, PATCH, DELETE, OPTIONS',
        //     'Access-Control-Allow-Headers': '*'
        // },
      });

      await api.get('https://vmplastic.com/banner_list.php')
        .then(function (response) {
          if (response.data.data) {
            console.log(response.data.data);
            for (var i = 0; i < response.data.data.length; i++) {
              this.data.push("/"+response.data.data[i].path);
            }
          } else {
            console.log('error');
              Swal.fire(
              'Error',
              '',
              'error'
            );
          }  
        }.bind(this))
        .catch(function (error) {
          console.error(error);
          Swal.fire(
            'Error',
            '',
            'error'
          )
        })
        .finally(function () {
          // always executed
        });
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.carousel__pagination {
  /* margin: 0px 0 0 !important; */
}
</style>
<template>
    <!-- Main Sidebar Container -->
    <aside class="main-sidebar sidebar-dark-primary elevation-4">
      <!-- Brand Logo -->
      <a href="#" class="brand-link">
        <span class="brand-text font-weight-light">VMPLASTIC</span>
      </a>
  
      <!-- Sidebar -->
      <div class="sidebar">
        <!-- Sidebar user panel (optional) -->
        <div class="user-panel mt-3 pb-3 mb-3 d-flex">
          <!-- <div class="image">
            <img src="assets/img/avatar5.png" class="img-circle elevation-2" alt="User Image">
          </div> -->
          <div class="info">
            <a href="#" class="d-block">{{ user.name }}</a>
          </div>
        </div>
  
        <!-- Sidebar Menu -->
        <nav class="mt-2">
          <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
            <!-- Add icons to the links using the .nav-icon class
                 with font-awesome or any other icon font library -->
            <li class="nav-item menu-open">
              <ul class="nav nav-treeview">
                <li class="nav-item">
                  <a href="/admin/dashboard" class="nav-link" :class="{active: currentRouteName == 'AdminMainComponent'}">
                    <i class="far fa-circle nav-icon"></i>
                    <p>Dashboard</p>
                  </a>
                </li>
                <li class="nav-item">
                  <a href="/admin/product" class="nav-link" 
                    :class="{active: currentRouteName == 'AdminProductComponent' || currentRouteName == 'AdminProductCreateComponent' || currentRouteName == 'AdminProductUpdateComponent'}">
                    <i class="far fa-circle nav-icon"></i>
                    <p>Product</p>
                  </a>
                </li>
                <li class="nav-item">
                  <a href="/admin/category" class="nav-link" 
                    :class="{active: currentRouteName == 'AdminCategoryComponent' || currentRouteName == 'AdminCategoryCreateComponent' || currentRouteName == 'AdminCategoryUpdateComponent'}">
                    <i class="far fa-circle nav-icon"></i>
                    <p>Category</p>
                  </a>
                </li>
                <li class="nav-item">
                  <a href="/admin/banner" class="nav-link" 
                    :class="{active: currentRouteName == 'AdminBannerComponent' || currentRouteName == 'AdminBannerCreateComponent' || currentRouteName == 'AdminBannerUpdateComponent'}">
                    <i class="far fa-circle nav-icon"></i>
                    <p>Banner</p>
                  </a>
                </li>
                <li class="nav-item">
                  <a href="/admin/promotion" class="nav-link" 
                    :class="{active: currentRouteName == 'AdminPromotionComponent' || currentRouteName == 'AdminPromotionCreateComponent' || currentRouteName == 'AdminPromotionUpdateComponent'}">
                    <i class="far fa-circle nav-icon"></i>
                    <p>Promotion</p>
                  </a>
                </li>
                <li class="nav-item">
                  <a href="/admin/user" class="nav-link" 
                    :class="{active: currentRouteName == 'AdminUserComponent' || currentRouteName == 'AdminUserCreateComponent' || currentRouteName == 'AdminUserUpdateComponent'}">
                    <i class="far fa-circle nav-icon"></i>
                    <p>User</p>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
        <!-- /.sidebar-menu -->
      </div>
      <!-- /.sidebar -->
    </aside>
  </template>
  
<script>
const axios = require('axios')
const Swal = require('sweetalert2')

export default {
  name: 'AdminSidebarComponent',
  data() {
    return {
      user: {}
    }
  },
  async created () {
    console.log('TOKEN :: ', localStorage.getItem('token'))

    let api = axios.create({
        timeout: 60 * 1000, // 1 minute
        // headers: {
        //     'Content-Type': 'application/json',
        //     'Access-Control-Allow-Origin': '*',
        //     'Access-Control-Allow-Methods': 'GET, PUT, POST, PATCH, DELETE, OPTIONS',
        //     'Access-Control-Allow-Headers': '*'
        // },
      });

      await api.get('https://vmplastic.com/profile.php', {
          headers: {
            'Authorization': localStorage.getItem('token')
          }
        })
        .then(function (response) {
          if (response.data.data) {
            console.log(response.data.data);
            this.user = response.data.data;
          } else {
            console.log('error');
            Swal.fire(
              'Error',
              response.data.message,
              'error'
            );
          }  
        }.bind(this))
        .catch(function (error) {
          console.error(error);
          Swal.fire(
            'Error',
            '',
            'error'
          )
        })
        .finally(function () {
          // always executed
        });
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
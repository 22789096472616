<template>
  <div class="main">
    <div class="row mb-3">
      <div class="col-12">
        <h1>{{ id == null ? 'Create' : 'Edit' }} Promotion</h1>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <div class="form-group">
          <label for="exampleInputName">Title</label>
          <input type="text" class="form-control" id="exampleInputName" placeholder="Enter Title" v-model="data.title" maxlength="100">
        </div>
        <div class="form-group">
          <label for="exampleInputDescription">Description</label>
          <textarea class="form-control" v-model="data.description" rows="5"></textarea>
        </div>
        <div class="form-group">
          <label for="exampleInputFile">File input</label>
          <div class="input-group">
            <div class="custom-file">
              <input type="file" class="custom-file-input" id="exampleInputFile" @change="upload_image($event)">
              <label class="custom-file-label" for="exampleInputFile">{{ data.image != null ? data.image.name : 'Choose file'  }}</label>
            </div>
            <div class="input-group-append">
              <span class="input-group-text">Upload</span>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <button type="button" class="btn btn-primary" v-on:click="update_promotion()">{{ id == null ? 'Create' : 'Edit' }}</button>
      </div>
    </div>
  </div>
</template>
  
<script>
import { useRoute } from 'vue-router'

const axios = require('axios')
const Swal = require('sweetalert2')

export default {
  name: 'AdminPromotionUpdateComponent',
  data() {
    return {
      id: null,
      data: {
        title: null,
        description: null,
        image: null
      }
    }
  },
  async created(){
    const route = useRoute()

    if (typeof route.params.id != 'undefined') {
      this.id = route.params.id
      await this.get_promotion()
    }
  },
  methods: {
    upload_image(event){
      if (event.target.files[0]) {
        this.data.image = event.target.files[0];
        console.log('upload_image', this.data.image);
      }
    },
    async get_promotion() {
      let api = axios.create({
        timeout: 60 * 1000, // 1 minute
        // headers: {
        //     'Content-Type': 'application/json',
        //     'Access-Control-Allow-Origin': '*',
        //     'Access-Control-Allow-Methods': 'GET, PUT, POST, PATCH, DELETE, OPTIONS',
        //     'Access-Control-Allow-Headers': '*'
        // },
      });

      await api.get('https://vmplastic.com/promotion_list.php?id=' + this.id, {
          headers: {
            'Authorization': localStorage.getItem('token')
          }
        })
        .then(function (response) {
          if (response.data.data) {
            console.log(response.data.data);
            this.data.title = response.data.data[0].title;
            this.data.description = response.data.data[0].description;
          } else {
            console.log('error');
              Swal.fire(
              'Error',
              '',
              'error'
            );
          }  
        }.bind(this))
        .catch(function (error) {
          console.error(error);
          Swal.fire(
            'Error',
            '',
            'error'
          )
        })
        .finally(function () {
          // always executed
        });
    },
    async update_promotion() {
      console.log('update_promotion', this.data);
      if (this.data.title == null) {
        Swal.fire(
          'Error',
          'Please input title',
          'error'
        );
      } else if (this.data.description == null) {
        Swal.fire(
          'Error',
          'Please input description',
          'error'
        );
      } else {
        let api = axios.create({
          timeout: 60 * 1000, // 1 minute
          headers: {
            'Content-Type': 'multipart/form-data'
          //     'Content-Type': 'application/json',
          //     'Access-Control-Allow-Origin': '*',
          //     'Access-Control-Allow-Methods': 'GET, PUT, POST, PATCH, DELETE, OPTIONS',
          //     'Access-Control-Allow-Headers': '*'
          },
        });

        let form = new FormData();
        form.append('action', this.id == null ? 'create' : 'update');
        form.append('id', this.id);
        form.append('title', this.data.title);
        form.append('description', this.data.description);
        if (this.data.image != null)
          form.append('image', this.data.image);

        await api.post('https://vmplastic.com/promotion.php', form, {
            headers: {
              'Authorization': localStorage.getItem('token')
            }
          })
          .then(function (response) {
            if (response.data.is_error == false) {
              console.log(response.data);
              if (response.data.is_error == false && response.data.message == null) {
                this.data.title = response.data.data.title;
                this.data.description = response.data.data.description;

                Swal.fire(
                  'Success',
                  '',
                  'success'
                ).then((result) => {
                  if (result.value) {
                    this.$router.push({name: 'AdminPromotionComponent'})
                  }
                });
              }
            } else {
              console.log('error');
              Swal.fire(
                'Error',
                '',
                'error'
              );
            }  
          }.bind(this))
          .catch(function (error) {
            console.error(error);
            Swal.fire(
              'Error',
              '',
              'error'
            )
          })
          .finally(function () {
            // always executed
          });
      }
    }
  }
}
</script>
  
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
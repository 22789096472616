<template>
  <div class="main">
    <div class="row mb-3">
      <div class="col-12">
        <h1>Profile</h1>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-body">
      <div class="form-group">
        <label for="exampleInputName">Email</label>
        <input type="text" class="form-control" id="exampleInputName" placeholder="Enter Username"
          v-model="user.username" maxlength="100">
      </div>
      <div class="form-group">
        <label for="exampleInputName">Name</label>
        <input type="text" class="form-control" id="exampleInputName" placeholder="Enter Name"
          v-model="user.name" maxlength="100">
      </div>
    </div>
    <div class="card-footer">
      <button type="button" class="btn btn-primary" v-on:click="submit()">Submit</button>
    </div>
  </div>
</template>
    
<script>
const axios = require('axios')
const Swal = require('sweetalert2')

export default {
  name: 'AdminProfileComponent',
  data() {
    return {
      user: {
        id: "",
        username: "",
        name: ""
      }
    }
  },
  async created() {
    await this.get_profile()
  },
  methods: {
    async get_profile() {
      let api = axios.create({
        timeout: 60 * 1000, // 1 minute
        // headers: {
        //     'Content-Type': 'application/json',
        //     'Access-Control-Allow-Origin': '*',
        //     'Access-Control-Allow-Methods': 'GET, PUT, POST, PATCH, DELETE, OPTIONS',
        //     'Access-Control-Allow-Headers': '*'
        // },
      });

      await api.get('https://vmplastic.com/profile.php', {
          headers: {
            'Authorization': localStorage.getItem('token')
          }
        })
        .then(function (response) {
          if (response.data.data) {
            console.log(response.data.data);
            this.user.id = response.data.data.id;
            this.user.username = response.data.data.username;
            this.user.name = response.data.data.name;
          } else {
            console.log('error');
            Swal.fire(
              'Error',
              response.data.message,
              'error'
            );
          }  
        }.bind(this))
        .catch(function (error) {
          console.error(error);
          Swal.fire(
            'Error',
            '',
            'error'
          )
        })
        .finally(function () {
          // always executed
        });
    },
    async submit() {
      if (this.user.username == "" || this.user.username == null) {
        Swal.fire(
          'Error',
          'Please input username.',
          'error'
        );
      } else if (this.user.name == "" || this.user.name == null) {
        Swal.fire(
          'Error',
          'Please input name.',
          'error'
        );
      } else {
        let api = axios.create({
          timeout: 60 * 1000, // 1 minute
          // headers: {
          //     'Content-Type': 'application/json',
          //     'Access-Control-Allow-Origin': '*',
          //     'Access-Control-Allow-Methods': 'GET, PUT, POST, PATCH, DELETE, OPTIONS',
          //     'Access-Control-Allow-Headers': '*'
          // },
        });

        await api.post('https://vmplastic.com/update_profile.php', this.user, {
          headers: {
            'Authorization': localStorage.getItem('token')
          }
        })
        .then(function (response) {
          if (response.data.is_error == false) {
            console.log(response.data.data);
            Swal.fire({
              title: 'Update Profile Success!',
              icon: 'success'
            }).then((result) => {
              if (result.value) {
                window.location.reload();
              }
            });
          } else {
            console.log('error');
            Swal.fire(
              'Error',
              response.data.message,
              'error'
            );
          }  
        }.bind(this))
        .catch(function (error) {
          console.error(error);
          Swal.fire(
            'Error',
            '',
            'error'
          )
        })
        .finally(function () {
          // always executed
        });
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
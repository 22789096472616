<template>
  <div class="main">
    <div class="row mb-3">
      <div class="col-12">
        <h1>{{ id == null ? 'Create' : 'Edit' }} Product</h1>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="form-group">
          <label for="exampleInputCategory">Category</label>
          <select class="form-control" v-model="data.category_id">
            <option value="">Select</option>
            <option v-for="(item, index) in categories" :key="index" :value="item.id">{{ item.name }}</option>
          </select>
        </div>
        <div class="form-group">
          <label for="exampleInputName">Name</label>
          <input type="text" class="form-control" id="exampleInputName" placeholder="Enter name" v-model="data.name">
        </div>
        <div class="form-group">
          <label for="exampleInputDescription">Description</label>
          <input type="text" class="form-control" id="exampleInputDescription" placeholder="Enter description" v-model="data.description">
        </div>
        <div class="form-group">
          <label for="exampleInputPrice">Price</label>
          <input type="text" class="form-control" id="exampleInputPrice" placeholder="Enter price" v-model="data.price">
        </div>
        <div class="form-group">
          <label for="exampleInputFile">File input</label>
          <div class="input-group">
            <div class="custom-file">
              <input type="file" class="custom-file-input" id="exampleInputFile" @change="upload_image($event)">
              <label class="custom-file-label" for="exampleInputFile">{{ data.image != null ? data.image.name : 'Choose file'  }}</label>
            </div>
            <div class="input-group-append">
              <span class="input-group-text">Upload</span>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <button type="button" class="btn btn-primary" v-on:click="update_product()">{{ id == null ? 'Create' : 'Edit' }}</button>
      </div>
    </div>
  </div>
</template>
  
<script>
import { useRoute } from 'vue-router'

const axios = require('axios')
const Swal = require('sweetalert2')

export default {
  name: 'AdminProductUpdateComponent',
  data() {
    return {
      id: null,
      data: {
        category_id: "",
        name: null,
        description: null,
        price: 0,
        image: null
      },
      categories: []
    }
  },
  async created() {
    const route = useRoute()
    if (typeof route.params.id != 'undefined') {
      this.id = route.params.id
      await this.get_product()
    }

    await this.get_category_list()
  },
  methods: {
    upload_image(event){
      if (event.target.files[0]) {
        this.data.image = event.target.files[0];
        console.log('upload_image', this.data.image)
      }
    },
    async get_category_list() {
      let api = axios.create({
        timeout: 60 * 1000, // 1 minute
        // headers: {
        //     'Content-Type': 'application/json',
        //     'Access-Control-Allow-Origin': '*',
        //     'Access-Control-Allow-Methods': 'GET, PUT, POST, PATCH, DELETE, OPTIONS',
        //     'Access-Control-Allow-Headers': '*'
        // },
      });

      await api.get('https://vmplastic.com/category_list.php', {
          headers: {
            'Authorization': localStorage.getItem('token')
          }
        })
        .then(function (response) {
          if (response.data.data) {
            console.log(response.data.data);
            this.categories = response.data.data;
          } else {
            console.log('error');
              Swal.fire(
              'Error',
              '',
              'error'
            );
          }  
        }.bind(this))
        .catch(function (error) {
          console.error(error);
          Swal.fire(
            'Error',
            '',
            'error'
          )
        })
        .finally(function () {
          // always executed
        });
    },
    async get_product() {
      let api = axios.create({
        timeout: 60 * 1000, // 1 minute
        // headers: {
        //     'Content-Type': 'application/json',
        //     'Access-Control-Allow-Origin': '*',
        //     'Access-Control-Allow-Methods': 'GET, PUT, POST, PATCH, DELETE, OPTIONS',
        //     'Access-Control-Allow-Headers': '*'
        // },
      });

      await api.get('https://vmplastic.com/product_list.php?id=' + this.id, {
          headers: {
            'Authorization': localStorage.getItem('token')
          }
        })
        .then(function (response) {
          if (response.data.data) {
            console.log(response.data.data);
            this.data.name = response.data.data[0].name;
            this.data.description = response.data.data[0].description;
            this.data.category_id = response.data.data[0].category_id;
            this.data.price = response.data.data[0].price;
          } else {
            console.log('error');
              Swal.fire(
              'Error',
              '',
              'error'
            );
          }  
        }.bind(this))
        .catch(function (error) {
          console.error(error);
          Swal.fire(
            'Error',
            '',
            'error'
          )
        })
        .finally(function () {
          // always executed
        });
    },
    async update_product() {
      console.log('update_product', this.data);
      if (this.data.category_id == "" || this.data.category_id == null) {
        Swal.fire(
          'Error',
          'Please input category',
          'error'
        );
      } else if (this.data.name == null) {
        Swal.fire(
          'Error',
          'Please input name',
          'error'
        );
      } else if (this.data.description == null) {
        Swal.fire(
          'Error',
          'Please input description',
          'error'
        );
      } else if (this.data.price == null) {
        Swal.fire(
          'Error',
          'Please input price',
          'error'
        );
      } else {
        let api = axios.create({
          timeout: 60 * 1000, // 1 minute
          headers: {
            'Content-Type': 'multipart/form-data'
          //     'Content-Type': 'application/json',
          //     'Access-Control-Allow-Origin': '*',
          //     'Access-Control-Allow-Methods': 'GET, PUT, POST, PATCH, DELETE, OPTIONS',
          //     'Access-Control-Allow-Headers': '*'
          },
        });

        let form = new FormData();
        form.append('action', this.id == null ? 'create' : 'update');
        form.append('id', this.id);
        form.append('name', this.data.name);
        form.append('description', this.data.description);
        form.append('category_id', this.data.category_id);
        form.append('price', this.data.price);
        if (this.data.image != null)
          form.append('image', this.data.image);

        await api.post('https://vmplastic.com/product.php', form, {
            headers: {
              'Authorization': localStorage.getItem('token')
            }
          })
          .then(function (response) {
            if (response.data.is_error == false) {
              console.log(response.data);
              if (response.data.is_error == false && response.data.message == null) {
                this.data.name = response.data.data.name;
                this.data.description = response.data.data.description;
                this.data.category_id = response.data.data.category_id;
                this.data.price = response.data.data.price;

                Swal.fire(
                  'Success',
                  '',
                  'success'
                ).then((result) => {
                  if (result.value) {
                    this.$router.push({name: 'AdminProductComponent'})
                  }
                });
              }
            } else {
              console.log('error');
              Swal.fire(
                'Error',
                response.data.message,
                'error'
              );
            }  
          }.bind(this))
          .catch(function (error) {
            console.error(error);
            Swal.fire(
              'Error',
              '',
              'error'
            )
          })
          .finally(function () {
            // always executed
          });
      }
    }
  }
}
</script>
  
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>